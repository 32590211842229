<template>
    <div>
    <div class="background">
        <div class="logo"></div>
        <div v-if="anchorHover!=true" class="anchor anchor-text" 
        @click="ScrollToAnchor('home-content')" 
        @mouseenter="SetAnchorText()"><b-icon-chevron-double-down/></div>
        <div v-else class="anchor anchor-text" 
        @click="ScrollToAnchor('home-content')"  
        @mouseleave="SetAnchorText()">Scroll Down</div>
    </div>
    <div ref="home-content" class="home-content">
        <br><br><br><br>
        <header><h3 class="home-head"> About Alpha Phi Omega</h3></header>
        <b-img-lazy
        src="../assets/initiation.jpg"
        alt="initiation"
        v-bind="imageProps"
        />
        <br><br>
        <p class="lead">
            Alpha Phi Omega is a co-ed collegiate community service
            fraternity that strives to develop three cardinal principles:
            Leadership, Friendship, and Service. The Iota Phi Chapter at UC
            Davis is among the largest in the nation, with members serving more than
            10,000 hours in the Davis community each year.
        </p>
        <div>
        </div>
        <br><br>
        <h3 class="home-head">Come Join Us!</h3>
        <b-img-lazy
        src ="../assets/flyer.png"
        alt="flyer"
        v-bind="imageProps"
        />
        <br><br>
        <p class='lead'>
            If you're interested in making friends and making a difference
            in the Davis community, come to our RUSH week events! During RUSH
            week, you'll be able to meet the brothers and hangout with us in a 
            series of fun and games.
        </p>
        <a href="/rush">More Info Here</a>
    </div>
    </div>
</template>

<script>

export default {
    name: 'Home',
    data: function() {
        return {
            anchorHover: false,
            imageProps: {
            width: '700',
            height: 'auto',
            center: true,
            fluidGrow: false,
            blank: true,
            blankColor: '#bbb',
            class: "img-fluid"
            }
        }
    },
    methods: {
        ScrollToAnchor(refName) {
            let element = this.$refs[refName];
            element.scrollIntoView({ behavior: 'smooth' });
            },
        SetAnchorText() {
            this.anchorHover = !this.anchorHover
        },
    },
}
</script>

<style scoped>

.background{
    background: radial-gradient(rgba(0,0,0,0),rgba(0,0,0,0.7)),
    url(../assets/bg.jpg);
    background-position: bottom;
    background-size: cover;
    background-attachment: fixed;
    display: grid;
    position: relative;
    height: calc(100vh);
    z-index: 1;
    }

.anchor{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.anchor:hover{
    cursor: pointer;
}

.logo {
    background-image:url('../assets/main_logo.png');
    background-size: 420px 420px;
    background-position: center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    max-width: 100%;
    z-index: -1;
}

.home-head{
    color: #298abd;
}
  
.learn-more {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 98%;
}
.home-content{
    margin: 10px 10%;
    text-align: center;
}
.anchor-text {
    color: #ffffff;
    text-shadow: 2px 2px 7px #000000;
    border: none;
    outline: none;
    font-weight: bold;
    background-color: transparent;
  }


</style>