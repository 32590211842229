import axios from "axios";

const handleCSRF = (config) => {
  const xsrfToken = $cookies.get('iphi3-cookie')
  config.headers['X-CSRFToken'] = xsrfToken;
  return config
}

axios.defaults.xsrfCookieName = 'csrftoken',
axios.defaults.xsrfHeaderName = 'x-csrftoken'

export default axios.create({
  baseURL: "/api",
  responseType: "json",
  withCredentials: true,
});
