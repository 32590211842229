import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import( '../views/Calendar.vue')
  },
  {
    path: '/history',
    name: 'History',
  
    component: () => import( '../views/History.vue')
  },
  {
    path: '/rush',
    name: 'Rush',
  
    component: () => import( '../views/Rush.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
  
    component: () => import( '../views/Dashboard.vue')
  },
  {
    path: '/me',
    name: 'Me',
  
    component: () => import( '../views/Me.vue')
  },
  {
    path: '/event/:id',
    name: 'Event',
  
    component: () => import( '../views/Event.vue')
  },
  {
    path: '/people/class',
    name: 'ClassViewer',
    component: () => import( '../views/ClassViewer.vue')
  },
  {
    path: '/people/xpressions',
    name: 'Xpressions',
    component: () => import( '../views/Xpressions.vue')
  },
  {
    path: '/user/tracking',
    name: 'UserTracking',
    component: () => import( '../views/UserTracking.vue')
  },
  {
    path: '/excomm/create/user',
    name: 'CreateUser',
    component: () => import( '../views/CreateUser.vue')
  },
  {
    path: '/excomm/edit/event/:id',
    name: 'EditEvent',
    component: () => import( '../views/EditEvent.vue')
  },
  {
    path: '/excomm/create/event',
    name: 'CreateEvent',
    component: () => import( '../views/CreateEvent.vue')
  },
  {
    path: '/excomm/create/xpressions',
    name: 'CreateXpressions',
    component: () => import( '../views/CreateXpressions.vue')
  },
  {
    path: '/excomm/tracking',
    name: 'ExcommTracking',
    component: () => import( '../views/ExcommTracking.vue')
  },
  {
    path: '/excomm/pparent',
    name: 'PParent',
    component: () => import( '../views/PParent.vue'),
  },
  {
    path: '/excomm/pparent/fam-reveal',
    name: 'FamilyReveal',
    component: () => import( '../views/FamilyReveal.vue')
  },
  {
    path: '/excomm/svp',
    name: 'ServicePortal',
    component: () => import( '../views/ServicePortal.vue')
  },
  {
    path: '/excomm/mvp',
    name: 'MembershipPortal',
    component: () => import( '../views/MembershipPortal.vue')
  },
  // Invalid route handlers
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import( '../views/Forbidden.vue')
  },
  {
    path: '/ohnoimlost',
    name: 'NotFound',
    component: () => import( '../views/NotFound.vue')
  },
  {
    path: '*',
    redirect: '/ohnoimlost',
  },
]

 const router = new VueRouter({
  mode: 'history',
  routes
})

export default router