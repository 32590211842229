<template>
<div>
    <Navbar v-if="$route.name!=('FamilyReveal')"/>
    <router-view/>
</div>
</template>

<script>
import Navbar from './components/Navbar'
import decode from 'jwt-decode'
export default {
  components: {
    Navbar
  },
  mounted: function(){
    if(this.$store.getters.isLoggedIn){
      this.$store.commit('setUser', JSON.parse(window.localStorage.getItem('user')))
    }
  }
}
</script>

<style lang="css">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.4s;
  transition-property: opacity;
  transition-timing-function: linear;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
