import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: !!localStorage.getItem('user'),
    full_name: '',
    id:''
  },
  mutations: {
    logout: state => {
      state.isLoggedIn = false
      localStorage.removeItem('user')
    },
    login: state => state.isLoggedIn = true,
    setUser: (state,payload) => {
      state.full_name = payload.full_name
      state.id = payload.id
    }
  },
  actions:{

  },
  getters: {
    isLoggedIn: state => !!state.isLoggedIn,
    isExcomm: state => {
      if(!state.isLoggedIn) return false
      return state.role === 'excomm'
    },
    getName: state => {
      return state.full_name
    },
    getId: state => {
      return state.id
    },

  },
  modules: {
  }
})
