<template>
    <div>
    <b-navbar v-scroll="updateScroll" toggleable="lg" type="dark" v-bind:variant="isHome" fixed="top">
    <b-navbar-brand :href="$store.getters.isLoggedIn?'/dashboard':'/'"><span style="color: #298abd; font-weight:bold; font-size: 30px">
        ΑΦΩ</span> <span style="font-size: 32px; font-weight: lighter ">|</span>
        <span style="color: #D2B55B; font-size:30px; font-weight:bold"> ΙΦ </span></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse :key="isHome" id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item v-if="($store.getters.isLoggedIn && !$store.getters.isExcomm)" href="/me">Me</b-nav-item>
        <b-nav-item href='/calendar'>Calendar</b-nav-item>
        <b-nav-item v-if="$store.getters.isLoggedIn" href="/user/tracking">Tracking <b-badge>0</b-badge></b-nav-item>
        <b-nav-item v-if="$store.getters.isExcomm">Stats</b-nav-item>
        <b-nav-item-dropdown v-if="$store.getters.isExcomm" text="Excomm Portal" right>
          <b-dropdown-item  href="/excomm/pparent">PParent</b-dropdown-item>
          <b-dropdown-item  href="/excomm/mvp">Membership VP</b-dropdown-item>
          <b-dropdown-item  href="/excomm/svp">Service VP</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown v-if="$store.getters.isExcomm" text="Create" right>
          <b-dropdown-item  href="/excomm/create/user">User</b-dropdown-item>
          <b-dropdown-item  href="/excomm/create/event">Event</b-dropdown-item>
          <b-dropdown-item  href="/excomm/create/xpressions">Xpressions</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown v-if="$store.getters.isLoggedIn && !$store.getters.isExcomm" text="People" right>
          <b-dropdown-item  href="/people/class">Class viewer</b-dropdown-item>
          <b-dropdown-item  href="/people/xpressions">Xpressions</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown toggle-class="custom-dropdown" v-if="$store.getters.isLoggedIn && !$store.getters.isExcomm" text="Docs" right>
          <b-dropdown-item 
          href="https://drive.google.com/drive/folders/0B6quac5Bmu42UnNuQ1ZBSjdZbDA">Iphi Docs</b-dropdown-item>
          <b-dropdown-item 
          href="https://drive.google.com/file/d/0B2J6UOoX_lMCa3JqaGdoUUhScEk/view">Constitution</b-dropdown-item>
          <b-dropdown-item 
          href="https://drive.google.com/drive/u/0/folders/0B6quac5Bmu42UnNuQ1ZBSjdZbDA">Bylaws</b-dropdown-item>
          <b-dropdown-item 
          href="https://drive.google.com/open?id=0B2J6UOoX_lMCSGV5TWR6ZE5zbDg">Rules of order</b-dropdown-item>
          <b-dropdown-item 
          href="https://drive.google.com/drive/folders/0Bxz8x7jc2uRmVTlCQ01MWXc3T0E?usp=sharing">Test Bank</b-dropdown-item>
        </b-nav-item-dropdown>
        
        <b-nav-item v-if="$store.getters.isLoggedIn" href='https://iotaphi.smugmug.com/'>Photos</b-nav-item>
        <b-nav-item href='https://wiki.iotaphi.org'>Wiki</b-nav-item>
        <b-nav-item v-if="!$store.getters.isLoggedIn" href='/rush'>Rush</b-nav-item>
        <b-nav-item v-if="!$store.getters.isLoggedIn" href='/history'>History</b-nav-item>
        <div v-if="$store.getters.isLoggedIn">
        <b-button variant="outline-danger" type="button" size="md" @click="userLogout">Logout</b-button>
        </div>
        <div v-else>
        <b-button v-b-modal.modal-center variant="outline-success" type="button" size="md">Login</b-button>
        </div>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
  <b-modal id="modal-center" 
  size="sm" 
  centered title="Login" 
  footer-bg-variant="light"
  footer-text-variant="dark">
    <form>
    <label for="email">Email:</label>
    <b-form-input id="email" type="email" v-model="email" placeholder="Enter your email" required></b-form-input>
    <br/>
    <label for="email">Password:</label>
    <b-form-input id="password" v-on:keyup.enter="userLogin" v-model="password" type="password" placeholder="Enter your password" required></b-form-input>
    <div v-if="loginFailed" style="color: red; text-align: center">
      <br/>
      Incorrect login credentials!
    </div></form>
    <template v-slot:modal-footer>
        <div class="w-100">   
          <b-button
            variant="warning"
            size="md"
            block
            type="submit"
            @click="userLogin" :disabled="loginDisable">
            Login
          </b-button>
        </div>
      </template>
  </b-modal>
  </div>
</template>

<script>
import API from '../API'
export default {
    name: 'Navbar',
    data: function(){
      return {
        isHome: this.$route.name ==='Home'? '' : 'dark',
        isTop: false,
        email: '',
        password: '',
        loginDisable: false,
        loginFailed: false,
      }  
    },
    methods: {
      updateScroll(){
        if(window.scrollY < 50 && this.$route.name ==='Home') this.isHome = ''
        else this.isHome = 'dark'
      },
      userLogin(){
        this.loginDisable = true
        API.post('/login', {username: this.email, password: this.password}
        ).then(response=>{
          this.loginDisable = false
          this.loginFailed = false
          this.email = ''
          this.password = ''
          this.$bvModal.hide('modal-center')
          window.localStorage.setItem('user',JSON.stringify(response.data)) 
          this.$store.commit('login')
          window.location.href = '/dashboard'

        }).catch(err=>{
          this.loginFailed = true
          this.loginDisable = false
        })

      },
      userLogout(){
        API.get('/logout'
        ).then(this.$store.commit('logout')).then(window.location.href = '/')
        }
     },
     mounted: function(){
       window.addEventListener('scroll', this.updateScroll)
     }
     
}
</script>

<style scoped>

 .btn.btn-warning{
   color: white;
 }

 ::v-deep .nav-link{
   color: rgb(224, 222, 222) !important;
    transition: 200ms all linear ;
 }
 ::v-deep .nav-link:hover{
   color: #999999 !important;
 }
</style>